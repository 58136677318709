import React, { useEffect, useState, useRef } from 'react';
import TextLinkButton from '../components/TextLinkButton';
import { ChevronRightBold } from '@openameba/spindle-ui/Icon';
import {
  ButtonIcon,
  CheckboxIcon,
  ColorIcon,
  IconographyIcon,
  ListIcon,
  ModalIcon,
  TextFieldIcon,
  TypographyIcon,
} from '../components/Icon';

import * as style from './Home.module.css';
import { Link } from 'gatsby';

export default function Home() {
  const [outView, setOutView] = useState(false);
  const target = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!target.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) =>
      setOutView(entry.intersectionRatio < 0.1),
    );
    observer.observe(target.current);

    return () => {
      observer.disconnect();
    };
  }, [target, setOutView]);

  return (
    <div
      className={
        outView ? `${style.container} ${style.isOutView}` : style.container
      }
    >
      <div className={style.heroVirtualityElement} ref={target} />
      <div className={style.hero}>
        <div className={style.heroInner}>
          <div className={style.heroMessage}>
            <img
              src="/images/home/hero-logo.png"
              alt="Ameba"
              className={`${style.heroAmeba} theme--light`}
              height="30"
              width="110"
            />
            <img
              src="/images/home/hero-logo-dark.png"
              alt="Ameba"
              className={`${style.heroAmeba} theme--dark`}
              height="30"
              width="110"
            />
            <h2 className={style.heroTitle}>
              <img
                src="/images/home/hero-title.svg"
                alt="つくる、つむぐ、つづく、"
                height="51"
                width="590"
                className="theme--light"
              />
              <img
                src="/images/home/hero-title-dark.svg"
                alt="つくる、つむぐ、つづく、"
                height="51"
                width="590"
                className="theme--dark"
              />
            </h2>
            <p className={style.heroLead}>
              デザインシステムSpindle（スピンドル）は"Amebaらしさ"を一貫してユーザーに届けるための仕組みです。"Amebaらしさ"がユーザーに届き、共感が生まれることで、サービスの信頼へとつながります。
            </p>
            <p className={style.heroLead}>
              "つくる、つむぐ、つづく、"を体現するために、Spindleを軸にして"Amebaらしさ"をつくります。
            </p>
            <div className={style.heroLink}>
              <TextLinkButton to="/brand/">もっと見る</TextLinkButton>
            </div>
          </div>
          <img
            src="/images/home/logo-good-design.png"
            className={style.logoGoodDesign}
            alt="GOOD DESIGN AWARD 2023"
            width="150"
            height="48"
          />
        </div>
      </div>

      {/* Amebaらしさを体現するために */}
      <section className={style.section}>
        <div className="layout-reveal-element">
          <div className={style.sectionInner}>
            <header className={style.sectionHeader}>
              <h2 className={style.sectionHeading}>
                <span>Ameba</span>らしさを
                <br />
                体現するために
              </h2>
              <div className={style.sectionIndex}>
                <img
                  src="/images/home/index_01_number.svg"
                  alt="01"
                  className={`${style.sectionIndexNumber} theme--inverse`}
                  height="24"
                  width="29"
                />
                <img
                  src="/images/home/index_01_title.svg"
                  alt="Brand &amp; Principles"
                  className={`${style.sectionIndexTitle} theme--inverse`}
                  height="13"
                  width="135"
                />
              </div>
              <div className={style.sectionLead}>
                <p>
                  SpindleにはAmebaを作るすべての人が「Amebaらしさ」をつくるための約束事や、それを手助けするツールやガイドラインが揃っています。
                </p>
              </div>
            </header>
            <div className={style.sectionBody}>
              <section className={style.principles}>
                <h3 className={style.principlesHeading}>デザイン原則</h3>
                <p className={style.principlesSubheading}>
                  Amebaらしさを作る約束事
                </p>
                <div className={style.principlesBody}>
                  <p>
                    Amebaのサービスを通じて、発信者や閲覧者といったユーザー、広告主や取引先といったパートナーとの接点において一貫した振る舞いをするためにどうあるべきかを「原則」として明記します。
                    「原則」は私たちを強く縛るルールではありません。良い信頼関係を築くために守りたい「約束事」です。
                  </p>
                </div>
                <div className={style.principlesLink}>
                  <TextLinkButton to="/principles/">もっと見る</TextLinkButton>
                </div>
              </section>
              <section className={style.principles}>
                <h3 className={style.principlesHeading}>アクセシビリティ</h3>
                <p className={style.principlesSubheading}>
                  誰もがいつでも、迷わず「書く」「読む」「応える」ができる状態を目指す
                </p>
                <div className={style.principlesBody}>
                  <p>
                    ユーザーが日々遭遇するあらゆる環境や状況の変化が障害になるデザインであってはいけません。Amebaはミッションの追求のために、アクセシビリティの向上に取り組みつづけています。
                  </p>
                </div>
                <div className={style.principlesLink}>
                  <TextLinkButton to="/principles/accessibility/">
                    もっと見る
                  </TextLinkButton>
                </div>
              </section>
              <section className={style.principles}>
                <h3 className={style.principlesHeading}>パフォーマンス</h3>
                <p className={style.principlesSubheading}>
                  「ユーザーが望むこと」を叶えるために
                </p>
                <div className={style.principlesBody}>
                  <p>
                    Amebaにとってパフォーマンスは「ユーザーが望むこと」を叶えるために重要です。興味のある記事はできるだけ早く読みたいと思いますし、書いた記事が必要な人に届いて欲しいと望むでしょう。サービスを早く安定して提供することが、より多くの人にコンテンツを届けることにつながります。
                  </p>
                </div>
                <div className={style.principlesLink}>
                  <TextLinkButton to="/principles/performance/">
                    もっと見る
                  </TextLinkButton>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <div className={style.keyVisualSub}>
        <div className="layout-reveal-element">
          <div className={style.keyVisualSubInner}>
            <picture className="theme--light">
              <source
                media="(max-width: 768px)"
                srcSet="/images/home/people-small.webp"
                height="214"
              />
              <img
                src="/images/home/people.webp"
                alt="コーポレートフォント「Ameba Sans」で表現された英数字記号とイラストで書かれた3名のキャラ。背景には2本の縄が描かれている。"
                height="103"
                width="300"
                loading="lazy"
              />
            </picture>
            <picture className="theme--dark">
              <source
                media="(max-width: 768px)"
                srcSet="/images/home/people-small-dark.webp"
                height="214"
              />
              <img
                src="/images/home/people-dark.webp"
                alt="コーポレートフォント「Ameba Sans」で表現された英数字記号とイラストで書かれた3名のキャラ。背景には2本の縄が描かれている。"
                height="103"
                width="300"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>

      {/* Amebaらしさを形作る */}
      <section className={style.section}>
        <div className="layout-reveal-element">
          <div className={style.sectionInner}>
            <header className={style.sectionHeader}>
              <h2 className={style.sectionHeading}>
                <span>Ameba</span>らしさを
                <br />
                形作る
              </h2>
              <div className={style.sectionIndex}>
                <img
                  src="/images/home/index_02_number.svg"
                  alt="02"
                  className={`${style.sectionIndexNumber} theme--inverse`}
                  height="24"
                  width="34"
                />
                <img
                  src="/images/home/index_02_title.svg"
                  alt="Styles &amp; Components"
                  className={`${style.sectionIndexTitle} theme--inverse`}
                  height="13"
                  width="151"
                />
              </div>
              <div className={style.sectionLead}>
                <p>
                  スタイルやコンポーネントは、Amebaらしさを伝えるための具体的な要素です。色やアイコン、タイポグラフィ、アクセシブルに設計・実装されたコンポーネントは、統合的なAmebaらしさの一貫性をもたらします。
                </p>
              </div>
            </header>
            <div className={style.sectionBody}>
              <ul className={style.libraries}>
                <li>
                  <Link to="/styles/color/" className={style.library}>
                    <ColorIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>カラー</p>
                      <p className={style.libraryEnName}>Color</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/components/button/" className={style.library}>
                    <ButtonIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>ボタン</p>
                      <p className={style.libraryEnName}>Button</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/styles/iconography/" className={style.library}>
                    <IconographyIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>アイコン</p>
                      <p className={style.libraryEnName}>Iconography</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/components/checkbox/" className={style.library}>
                    <CheckboxIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>チェックボックス</p>
                      <p className={style.libraryEnName}>Checkbox</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/styles/typography/" className={style.library}>
                    <TypographyIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>タイポグラフィ</p>
                      <p className={style.libraryEnName}>Typography</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/components/modal/" className={style.library}>
                    <ModalIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>モーダル</p>
                      <p className={style.libraryEnName}>Modal</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/components/list/" className={style.library}>
                    <ListIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>リスト</p>
                      <p className={style.libraryEnName}>List</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/components/textfield/" className={style.library}>
                    <TextFieldIcon
                      className={style.libraryIcon}
                      aria-hidden="true"
                    />
                    <div className={style.libraryTitle}>
                      <p className={style.libraryName}>テキストフィールド</p>
                      <p className={style.libraryEnName}>TextField</p>
                    </div>
                    <ChevronRightBold
                      className={style.libraryArrow}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className={style.keyVisualSub}>
        <div className="layout-reveal-element">
          <div className={style.keyVisualSubInner}>
            <picture className="theme--light">
              <source
                media="(max-width: 768px)"
                srcSet="/images/home/objects-small.webp"
                height="214"
                width="328"
              />
              <img
                src="/images/home/objects.webp"
                alt="Amebaのブランドカラー6種類。背景には2本の縄が描かれている。"
                height="103"
                width="300"
                loading="lazy"
              />
            </picture>
            <picture className="theme--dark">
              <source
                media="(max-width: 768px)"
                srcSet="/images/home/objects-small-dark.webp"
                height="214"
                width="328"
              />
              <img
                src="/images/home/objects-dark.webp"
                alt="Amebaのブランドカラー6種類。背景には2本の縄が描かれている。"
                height="103"
                width="300"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>

      {/* 事例から見るSpindle */}
      <section className={style.section}>
        <div className="layout-reveal-element">
          <div className={style.sectionInner}>
            <header className={style.sectionHeader}>
              <h2 className={style.sectionHeading}>
                事例から見る
                <br />
                <span>Spindle</span>
              </h2>
              <div className={style.sectionIndex}>
                <img
                  src="/images/home/index_03_number.svg"
                  alt="03"
                  className={`${style.sectionIndexNumber} theme--inverse`}
                  height="24"
                  width="35"
                />
                <img
                  src="/images/home/index_03_title.svg"
                  alt="Case studies"
                  className={`${style.sectionIndexTitle} theme--inverse`}
                  height="13"
                  width="83"
                />
              </div>
              <div className={style.sectionLead}>
                <p>
                  Spindleの事例集です。Ameba本体や関連事業などで採用が進んでいます。
                </p>
              </div>
            </header>
            <div className={style.sectionBody}>
              <ul className={style.caseStudies}>
                <li>
                  <div className={style.caseStudy}>
                    <h3 className={style.caseStudyTitle}>
                      <img
                        className="theme--light"
                        src="/images/logo-ameba.svg"
                        alt="Ameba"
                        height="37"
                        width="121"
                      />
                      <img
                        className="theme--dark"
                        src="/images/logo-ameba-inverse.svg"
                        alt="Ameba"
                        height="37"
                        width="121"
                      />
                    </h3>
                    <div className={style.caseStudyLead}>
                      <p>
                        日本最大規模のブログサービス「アメブロ」や、最新の芸能ニュースやゲームを無料で楽むことができます。「Ameba」でオフィシャルブログを展開する芸能人・有名人は20,000人を超え、ブログで発信される情報からたくさんの新たな記事や話題が生まれています。
                      </p>
                    </div>
                    <div className={style.caseStudyLink}>
                      <TextLinkButton to="https://ameba.jp">
                        Amebaを見る
                      </TextLinkButton>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={style.caseStudy}>
                    <h3 className={style.caseStudyTitle}>
                      <img
                        className="theme--light"
                        src="/images/logo-amebamanga.svg"
                        alt="Amebaマンガ"
                        height="48"
                        width="213"
                      />
                      <img
                        className="theme--dark"
                        src="/images/logo-amebamanga-inverse.svg"
                        alt="Amebaマンガ"
                        height="48"
                        width="213"
                      />
                    </h3>
                    <div className={style.caseStudyLead}>
                      <p>
                        電子コミックサービス 「Amebaマンガ 」
                        は話題のヒット作から懐かしの名作まで、250社以上のコンテンツ提供元より20万冊の電子コミックを取揃える漫画サービスです。
                        常時1500冊以上の無料漫画や毎日新しい話が無料で読める無料連載で気軽に漫画を楽しむことができます。
                      </p>
                    </div>
                    <div className={style.caseStudyLink}>
                      <TextLinkButton to="https://dokusho-ojikan.jp">
                        Amebaマンガを見る
                      </TextLinkButton>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={style.caseStudy}>
                    <h3 className={style.caseStudyTitle}>
                      <img
                        className="theme--light"
                        src="/images/logo-amebanews.svg"
                        alt="Ameba News"
                        height="48"
                        width="213"
                      />
                      <img
                        className="theme--dark"
                        src="/images/logo-amebanews-inverse.svg"
                        alt="Ameba News"
                        height="48"
                        width="213"
                      />
                    </h3>
                    <div className={style.caseStudyLead}>
                      <p>
                        AmebaNewsでは「ちょっと新しい日常を」をコンセプトに、芸能人・有名人のエンタメニュースを中心にお届けしています。
                        <br />
                        ※一部UIにてスタイルやコンポーネントを採用
                      </p>
                    </div>
                    <div className={style.caseStudyLink}>
                      <TextLinkButton to="https://news.ameba.jp/">
                        Ameba Newsを見る
                      </TextLinkButton>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={style.caseStudy}>
                    <h3 className={style.caseStudyTitle}>
                      <img
                        className="theme--light"
                        src="/images/logo-amebachoice.svg"
                        alt="ライフスタイル比較メディア Amebaチョイス"
                        height="48"
                        width="219"
                      />
                      <img
                        className="theme--dark"
                        src="/images/logo-amebachoice-inverse.svg"
                        alt="ライフスタイル比較メディア Amebaチョイス"
                        height="48"
                        width="219"
                      />
                    </h3>
                    <div className={style.caseStudyLead}>
                      <p>
                        Amebaチョイスは「人生、暮らしにおける"いい選択"を後押しする」メディアです。
                        <br />
                        選び方情報や独自のおすすめランキングによって、良い商品やサービスを見つけることができます。
                      </p>
                    </div>
                    <div className={style.caseStudyLink}>
                      <TextLinkButton to="https://choice.ameba.jp/">
                        Amebaチョイスを見る
                      </TextLinkButton>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Spindleに貢献する */}
      <section className={style.section}>
        <div className="layout-reveal-element">
          <div className={style.sectionInner}>
            <header className={style.sectionHeader}>
              <h2 className={style.sectionHeading}>
                Spindleに<span>貢献する</span>
              </h2>
              <div className={style.sectionIndex}>
                <img
                  src="/images/home/index_04_number.svg"
                  alt="04"
                  className={`${style.sectionIndexNumber} theme--inverse`}
                  height="24"
                  width="37"
                />
                <img
                  src="/images/home/index_04_title.svg"
                  alt="Contribution"
                  className={`${style.sectionIndexTitle} theme--inverse`}
                  height="13"
                  width="79"
                />
              </div>
              <div className={style.sectionLead}>
                <p>
                  SpindleはAmebaのすべての人たちの協力によって成り立っています。ちょっとしたフィードバックはもちろん、具体的な改善案なども歓迎します。
                </p>
              </div>
            </header>
            <div className={style.sectionBody}>
              <ul className={style.contributions}>
                <li>
                  <div className={style.contribution}>
                    <h3 className={style.contributionTitle}>GitHub</h3>
                    <div className={style.contributionLead}>
                      <p>
                        SpindleのGitHubリポジトリではコンポーネントやアイコンの配布をしています。これら配布されたものへのフィードバックや改善のリクエストを受け付けています。
                      </p>
                    </div>
                    <p className={style.contributionLink}>
                      <TextLinkButton to="https://github.com/openameba/spindle">
                        GitHubを見る
                      </TextLinkButton>
                    </p>
                  </div>
                </li>
                <li>
                  <div className={style.contribution}>
                    <h3 className={style.contributionTitle}>Slack</h3>
                    <div className={style.contributionLead}>
                      <p>
                        従業員の方は、メディア統括本部のSlack内
                        #ameba_designsystem_spindle
                        チャンネルにて、Spindleへのフィードバックを受け付けています。
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// extracted by mini-css-extract-plugin
export var caseStudies = "Home-module--caseStudies--c7ead";
export var caseStudyLead = "Home-module--caseStudy-lead--6fec4";
export var caseStudyLink = "Home-module--caseStudy-link--e3bba";
export var caseStudyTitle = "Home-module--caseStudy-title--1fa25";
export var container = "Home-module--container--a092f";
export var contributionLead = "Home-module--contribution-lead--50966";
export var contributionLink = "Home-module--contribution-link--09119";
export var contributionTitle = "Home-module--contribution-title--f2a26";
export var contributions = "Home-module--contributions--354f5";
export var hero = "Home-module--hero--6cd78";
export var heroInner = "Home-module--hero-inner--a9cbe";
export var heroLead = "Home-module--hero-lead--4bd3f";
export var heroLink = "Home-module--hero-link--c134c";
export var heroMessage = "Home-module--hero-message--9e654";
export var heroMotionLogo = "Home-module--hero-motion-logo--bdf01";
export var heroTitle = "Home-module--hero-title--cc076";
export var isOutView = "Home-module--is-out-view--13fad";
export var keyVisualMain = "Home-module--keyVisual-main--f07a6";
export var keyVisualSub = "Home-module--keyVisual-sub--f0af7";
export var keyVisualSubInner = "Home-module--keyVisual-sub-inner--5cbfe";
export var libraries = "Home-module--libraries--a2efb";
export var library = "Home-module--library--e85c4";
export var libraryArrow = "Home-module--library-arrow--28ffa";
export var libraryEnName = "Home-module--library-en-name--7a88f";
export var libraryIcon = "Home-module--library-icon--dcb9c";
export var libraryName = "Home-module--library-name--ed850";
export var libraryTitle = "Home-module--library-title--47466";
export var logoGoodDesign = "Home-module--logo-good-design--e63db";
export var move = "Home-module--move--a72e4";
export var principles = "Home-module--principles--b0942";
export var principlesBody = "Home-module--principles-body--ce5bd";
export var principlesHeading = "Home-module--principles-heading--25b76";
export var principlesLink = "Home-module--principles-link--22f31";
export var principlesSubheading = "Home-module--principles-subheading--91145";
export var section = "Home-module--section--05e8d";
export var sectionBody = "Home-module--section-body--5b2e5";
export var sectionHeader = "Home-module--section-header--2a048";
export var sectionHeading = "Home-module--section-heading--766ae";
export var sectionIndex = "Home-module--section-index--bb720";
export var sectionIndexNumber = "Home-module--section-index-number--ab88a";
export var sectionIndexTitle = "Home-module--section-index-title--2b69e";
export var sectionInner = "Home-module--section-inner--e2873";
export var sectionLead = "Home-module--section-lead--56180";